<template>
  <div>
    <Head title="Distribuidores" />
    <h1 class="mb-8 text-3xl font-bold">Clientes</h1>
    <div class="bg-white rounded-md shadow overflow-x-auto">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Nombre</th>
          <th class="pb-4 pt-6 px-6">Apellido</th>
          <th class="pb-4 pt-6 px-6">Identificacion</th>
          <th class="pb-4 pt-6 px-6">Compañia</th>
          <th class="pb-4 pt-6 px-6">Estado</th>
          <th class="pb-4 pt-6 px-6">Municipio</th>
          <th class="pb-4 pt-6 px-6">Telefono</th>
          <th class="pb-4 pt-6 px-6">Grupo</th>
        </tr>
        <tr v-for="dealer in dealers.data" :key="dealer.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.lastname }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.identification }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.company }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.state }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.town }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.phone }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500" :href="`/dealers/${dealer.id}/edit`">
              {{ dealer.group_info.name }}
            </Link>
          </td>
        </tr>
        <tr v-if="!dealers">
          <td class="px-6 py-4 border-t" colspan="4">No hay clientes.</td>
        </tr>
      </table>
    </div>
    <pagination class="mt-6" :links="dealers.links" />
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import Pagination from '@/Shared/Pagination.vue'

export default {
  components: {
    Head,
    Icon,
    Link,
    Pagination
  },
  layout: Layout,
  props: {
    dealers: Object,
  },
  data() {
    return {
      form: {

      },
    }
  },
}
</script>
