<template>
  <div>
    <Head title="Crear grupos" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link class="text-indigo-400 hover:text-indigo-600" href="/deposits">Depositos</Link>
      <span class="text-indigo-400 font-medium">/</span> Crear
    </h1>
    <div class="max-w-3xl bg-white rounded-md shadow overflow-hidden">
      <form @submit.prevent="store">
        <div class="flex flex-wrap -mb-8 -mr-6 p-8">
          <text-input v-model="form.name" :error="form.errors.name" class="pb-8 pr-6 w-full lg:w-1/2" label="Nombre" />
          <text-input v-model="form.alias" :error="form.errors.alias" class="pb-8 pr-6 w-full lg:w-1/2" label="Alias" />
          <text-input v-model="form.address" :error="form.errors.address" class="pb-8 pr-6 w-full lg:w-1/2" label="Direccion" />
          <text-input v-model="form.lat" :error="form.errors.lat" class="pb-8 pr-6 w-full lg:w-1/2" label="Latitud" />
          <text-input v-model="form.long" :error="form.errors.long" class="pb-8 pr-6 w-full lg:w-1/2" label="Longitud" />
          <select-input v-model="form.region_id" class="pb-8 pr-6 w-full lg:w-1/2" label="Region">
            <option value="">Seleccionar</option>
            <option v-for="(region, key) in regions" :key="key" :value="region.id">{{ region.name }}</option>
          </select-input>
        </div>
        <div class="flex items-center justify-end px-8 py-4 bg-gray-50 border-t border-gray-100">
          <loading-button :loading="form.processing" class="btn-indigo" type="submit">Crear Deposito</loading-button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput'
import LoadingButton from '@/Shared/LoadingButton'
import SelectInput from '@/Shared/SelectInput.vue'

export default {
  components: {
    Head,
    Link,
    LoadingButton,
    TextInput,
    SelectInput
  },
  layout: Layout,
  props: {
    regions: Object
  },
  remember: 'form',
  data() {
    return {
      form: this.$inertia.form({
        name: "",
        alias: "",
        address: "",
        lat: "",
        long: "",
        region_id: ""
      }),
    }
  },
  methods: {
    store() {
      this.form.post('/deposits')
    },
  },
}
</script>

