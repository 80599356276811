<template>
  <div>
    <Head title="Inventario" />
    <h1 class="mb-8 text-3xl font-bold">Devolución / {{ getDate(inventory.created_at) }}</h1>
    <div class="bg-white rounded-md shadow overflow-x-auto mb-5">
      <table class="w-full whitespace-nowrap">
        <tr class="text-left font-bold">
          <th class="pb-4 pt-6 px-6">Codigo</th>
          <th class="pb-4 pt-6 px-6">Producto</th>
          <th class="pb-4 pt-6 px-6">Cantidad</th>
        </tr>
        <tr v-for="product in inventory.products" :key="product.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.product.code }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.product.name }}
            </Link>
          </td>
          <td class="border-t">
            <Link class="flex items-center px-6 py-4 focus:text-indigo-500">
              {{ product.quantity }}
            </Link>
          </td>
        </tr>
        <tr v-if="!inventory.products">
          <td class="px-6 py-4 border-t" colspan="4">No hay productos.</td>
        </tr>
      </table>
    </div>
    <button v-if="inventory.status === 'request' && user.role == 'storer'" @click="aprobe" class="btn btn-indigo">
      Confirmar
    </button>
    <!-- <pagination class="mt-6" :links="vendors.links" /> -->
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Icon from '@/Shared/Icon'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'

export default {
  components: {
    Head,
    Icon,
    Link,
    TextInput,
  },
  layout: Layout,
  props: {
    inventory: Object,
    user: Object,
  },
  data() {
    return {
      form: {
        qtys: [],
      },
    }
  },
  methods: {
    getDate(date)
    {
      let dateNew = new Date(date)
      return dateNew.toLocaleString('es-VE', { hour12: false })
    },
    aprobe: function () {
      let confirm = window.confirm("Estas seguro que quieres aprobar esta solicitud?");
      if(confirm){
        this.$inertia.get(`/inventories/approve/${this.inventory.id}`);
      }
    },
  }
}
</script>
