<template>
  <div>
    <Head :title="`Pedido #${ order.number_order ?? order.id } | ${order.order_state == 'cancel' ? 'PEDIDO ANULADO' : ''}`" />
    <button class="bg-indigo-800 text-white rounded p-2 mr-2" @click="back()">
        <i class="fa fa-arrow-left"></i>
      </button>
    <h1 class="mb-8 text-3xl font-bold">
      Pedido #{{ order.number_order ?? order.id }} <span class="text-red-500" v-if="order.order_state == 'cancel'">PEDIDO ANULADO</span>
      <a target="_blank" :href="`/orders/${order.id}/report`" class="p-2 rounded bg-gray-400 mr-2">
        <i class="fa fa-print text-white" /> Proforma
      </a>
      <a target="_blank" :href="`/orders/${order.id}/report-storer`" class="p-2 rounded bg-gray-400">
        <i class="fa fa-print text-white" /> Nota de entrega
      </a>
    </h1>
    <div class="bg-white rounded-md shadow overflow-x-auto mb-5 p-5 flex justify-between">
      <div>
        <div class="pb-2">Cliente: {{ order.client.name }}</div>
        <div class="pb-2">Alias o Cédula: {{ order.client.identification }}</div>
        <div class="pb-2">Email: {{ order.client.email }}</div>
        <div class="pb-2">Telefono: {{ order.client.phone }}</div>
        <div class="pb-2"><strong>Balance:</strong> ${{ order.balance }}</div>
        <div class="pb-2"><strong>Deuda:</strong> ${{ order.total - order.balance }}</div>
        <div class="pb-2"><strong>Total:</strong> ${{ order.total }}</div>
        <div class="pb-2">
          <strong>Modalidad: </strong>
          {{ order.isCredit ? 'Credito' : 'Contado' }}
        </div>
        <div class="pb-2">
          <strong>Estado: </strong>
            <span v-if="order.order_state == 'cancel'">ANULADA</span>
            <span v-if="order.order_state == 'request'">PENDIENTE POR PAGO</span>
            <span v-if="order.order_state == 'finish'">FINALIZADA</span>
            <span v-if="order.order_state == 'pay'">PAGADA</span>
            <span v-if="order.order_state == 'delivery'">EN ESPERA DE DESPACHO</span>
        </div>
      </div>
      <div class="flex">
        <div>Vendedor {{ order?.responsible?.first_name }} {{ order?.responsible?.last_name }}</div>
      </div>
    </div>
    <div class="bg-white rounded-md shadow p-5 overflow-x-auto">
      <h2 class="mb-8 text-xl font-bold">Despachar</h2>
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr>
            <th class="pb-4 pt-6 px-6">

            </th>
            <th class="pb-4 pt-6 px-6">
              Producto
            </th>
            <th class="pb-4 pt-6 px-6">
              Descripcion
            </th>
            <th class="pb-4 pt-6 px-6">
              Deposito
            </th>
            <th class="pb-4 pt-6 px-6">
              Estado
            </th>
            <th>
              Cantidad
            </th>
            <th class="pb-4 pt-6 px-6">
              Precio
            </th>
            <th class="pb-4 pt-6 px-6">
              Total
            </th>
          </tr>
      </thead>
        <tbody>
          <tr v-for="(product, key) in order.products" :key="product.id" :class="`${product.dispatch ? 'bg-blue-300' : ''}`">
            <td class="border-t text-center p-2">
              <input v-model="products.products[key]['dispatch']" :value="product.id" type="checkbox">
            </td>
            <td class="border-t text-center p-2">
              {{ product.product.name }}
            </td>
            <td class="border-t text-center p-2">
              {{ product.product.description }}
            </td>
            <td class="border-t text-center p-2">
              {{ product.deposit.name }}
            </td>
            <td class="border-t text-center p-2">
              {{ product.dispatch ? 'Despachado' : 'No Despachado' }}
            </td>
            <td class="border-t text-center p-2">
              {{ product.quantity }}
            </td>
            <td class="border-t text-center p-2">
              ${{ product.price }}
            </td>
            <td class="border-t text-center p-2">
              ${{ Math.round(product.price * product.quantity) }}
            </td>
          </tr>
        </tbody>
        <tfoot class="px-6 py-2" style="background-color: #f3f3f3;">
          <tr>
            <td class="border-t text-center p-2"></td>
            <td class="border-t text-center p-2"></td>
            <td class="border-t text-center p-2"></td>
            <td class="border-t text-center p-2"></td>
            <td class="border-t text-center p-2"></td>
            <td class="border-t text-center p-2"></td>
            <td class="border-t text-center p-2"><strong>Total</strong></td>
            <td class="border-t text-center p-2">${{ order.total }}</td>
          </tr>
        </tfoot>

      </table>
      <button
        v-if="order.order_state == 'delivery' && role == 'storer'"
        @click="addDispatch"
        class="p-2 rounded bg-indigo-800 text-white text-md my-2">
        Despachar productos
      </button>
    </div>

    <div class="bg-white shadow p-5 mt-5">
      <h2 class="mb-8 text-xl font-bold">Despachos</h2>
      <table class="w-full whitespace-nowrap">
        <thead>
        <tr>
          <th class="pb-4 pt-6 px-6">Concepto</th>
          <th class="pb-4 pt-6 px-6">Productos</th>
          <th class="pb-4 pt-6 px-6">Fecha</th>
          <th class="pb-4 pt-6 px-6">Responsable</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="dispatch in dispatches" :key="dispatch.id" class="hover:bg-gray-100 focus-within:bg-gray-100">
          <td class="border-t text-center p-2">
            Se realizo el despacho al cliente
          </td>
          <td class="border-t text-center p-2">
            <span v-for="prod in dispatch.products">
              {{prod.product.name}},
            </span>
          </td>
          <td class="border-t text-center p-2">
            {{ dispatch.date }}
          </td>
          <td class="border-t text-center p-2">
            <!--{{ dispatch.responsible.first_name }} {{ dispatch.responsible.last_name }}-->
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <div v-if="role == 'financial'" class="bg-white shadow p-5 mt-5">
      <h2 class="mb-8 text-xl font-bold">Pagos</h2>
      <div class="pt-2">
        <div class="pb-8 pr-6 w-full">
          <label for="">Metodo de pago</label>
          <select v-model="form.method" class="form-input mt-2 w-full">
            <option value="">Seleccionar</option>
            <option value="efectivo">Efectivo</option>
            <option value="transferencia">Transferencia Bs</option>
            <option value="transferencia-usd">Transferencia Divisas</option>
            <option value="zelle">Zelle</option>
            <option value="credito">Credito</option>
          </select>
        </div>
        <text-input v-model="form.amount" type="number" step="0.10" :error="form.errors.long" class="pb-8 pr-6 w-full" label="Monto" />
        <text-input
          v-if="form.method === 'transferencia' || form.method === 'transferencia-usd' || form.method === 'zelle'"
          v-model="form.reference"
          :error="form.errors.reference"
          class="pb-8 pr-6 w-full"
          label="Referencia"
        />

        <button @click="addPay" class="p-2 rounded bg-indigo-800 text-white text-md my-2">
          Agregar pagos
        </button>

      </div>
      <div>
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr>
                <th class="pb-4 pt-6 px-6">Metodo</th>
                <th class="pb-4 pt-6 px-6">Monto</th>
                <th class="pb-4 pt-6 px-6">Fecha</th>
                <th class="pb-4 pt-6 px-6">Referencia</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="pay in order.pays" class="hover:bg-gray-100 focus-within:bg-gray-100">
              <td class="border-t text-center p-2 capitalize">
                {{ pay.method }}
              </td>
              <td class="border-t text-center p-2">
                {{ pay.amount }}
              </td>
              <td class="border-t text-center p-2">
                {{ pay.created_at }}
              </td>
              <td class="border-t text-center p-2">
                {{ pay.reference }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div v-if="order.pays.length == 0" class="bg-white shadow p-5 text-right mt-5">
      <button
        v-if="order.order_state != 'cancel'"
        @click="cancelOrder()" class="p-2 rounded bg-red-500 text-white text-md">
        Anular Orden
      </button>
    </div>

    <!-- <pagination class="mt-6" :links="vendors.links" /> -->
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import Layout from '@/Shared/Layout'
import TextInput from '@/Shared/TextInput.vue'

export default {
  components: {
    Head,
    Link,
    TextInput
  },
  layout: Layout,
  props: {
    order: Object,
    dispatches: Object
  },
  data() {
    return {
      role: this.$parent.auth.user.role,
      form: this.$inertia.form({
        amount: 0,
        method: "",
        order_id: this.order.id,
        reference: ''
      }),
      products: this.$inertia.form({
        products: this.order.products,
        order_id: this.order.id,
      })
    }
  },
  methods: {
    back() {
      window.history.back();
    },
    cancelOrder()
    {
      if(confirm("Estas seguro que quieres anular esta orden?"))
      {
        window.location.href = `/orders/${this.order.id}/cancel`
      }
    },
    addDispatch()
    {
      this.products.post('/orders/dispatch')
    },
    addPay()
    {
      if(this.form.method === 'transferencia' || this.form.method === 'transferencia-usd' || this.form.method === 'zelle')
      {
          if(!this.form.reference)
        {
          alert('Debe ingresar una referencia')
          return false;
        }
      }
      if(this.order.total < (parseInt(this.form.amount) + parseInt(this.order.balance)))
      {
        return alert("No se puede agregar este pago si el monto es mayor al balance");
      }
      this.form.order_id  = this.order.id
      this.form.post('/orders/pay')
      console.log(this.form)

      this.cleanPay()

    },
    cleanPay()
    {
      this.form = this.$inertia.form({
        amount: 0,
        method: "",
        order_id: this.order.id,
        reference: ''
      });
    }
  },
}
</script>
